// import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Route, appRoutes } from "react-router-dom";
// import { auth_appRoutes, appRoutes } from "./appRoutes";
// import Layout from "./layout/Layout";
// import { useSelector } from "react-redux";

// const App = () => {
//   const [user_details, setuser_details] = useState(null);
//   // const ass_token = localStorage.getItem("accessToken");
//   const ass_token = useSelector((state =>state.authinfo.access_token));
//   console.log("ass_token", ass_token);

//   const [a_appRoutes, seta_appRoutes] = useState(auth_appRoutes);
//   const [r_appRoutes, setr_appRoutes] = useState(appRoutes);
//   useEffect(() => {
//     if (ass_token !== null) {
//       setuser_details(ass_token);
//     } else {
//       setuser_details("");
//     }
//   }, [ass_token]);
//   console.log("ass_token is", ass_token);
//   console.log("ass_token is b", user_details);

//   // useEffect(() => {
//   //   localStorage.clear();
//   // }, [])
  

//   return (
//     <Router>
//       <appRoutes>
//         {user_details !== "" ? (
//           <Route path="/" element={<Layout />}>
//             {r_appRoutes.map((item, index) => {
//               return (
//                 <Route path={item.path} element={item.element} key={index} />
//               );
//             })}
//           </Route>
//         ) : (
//           <>
//             {a_appRoutes.map((item, index) => {
//               return (
//                 <Route path={item.path} element={item.element} key={index} />
//               );
//             })}
//           </>
//         )}
//       </appRoutes>
//     </Router>
//   );
// };

// export default App;

import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { authRoutes, appRoutes } from "./routes";
import Layout from "./layout/Layout";
import { useSelector } from "react-redux";
import Loader from "./component/Loader";

const App = () => {
  const [loading, setLoading] = useState(true);
  const accessToken = useSelector((state) => state.authinfo.access_token);

  useEffect(() => {
    setLoading(false);
  }, [accessToken]);

  if (loading) {
    return <Loader show={true} />;
  }

  return (
    <Router>
      <Suspense fallback={<Loader show={true} />}>
        <Routes>
          {accessToken ? (
            <Route path="/" element={<Layout />}>
              {appRoutes.map((route, index) => (
                <Route path={route.path} element={route.element} key={index} />
              ))}
            </Route>
          ) : (
            <>
              {authRoutes.map((route, index) => (
                <Route path={route.path} element={route.element} key={index} />
              ))}
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
