import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import { FiLogOut, FiLogIn } from "react-icons/fi";
import Loader from "../component/Loader";
import axios from "axios";
import { ServerAddress } from "../constants/ServerAddress";
import { useSelector } from "react-redux";

const Header = () => {
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);

  const toggleProfileDropdown = () => {
    setShowProfileDropdown((prevState) => !prevState);
  };

  const handleLogout = () => {
    // Implement logout functionality
    console.log("Logged out");
  };

  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("home");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const removeAccessTokenFromLocalStorage = () => {
    localStorage.removeItem("accessToken");
    localStorage.clear();
    navigate("/");
    window.location.reload();
    setshow(false);
  };

  const [show, setshow] = useState(false);

  // const token = localStorage.getItem("accessToken");
  const token = useSelector((state =>state.authinfo.access_token));

  const refresh_token = localStorage.getItem("refresh_token");

  const LogOutData = () => {
    removeAccessTokenFromLocalStorage();

    setshow(true);
    axios
      .post(
        ServerAddress + `ems/logout_user_details/`,
        {
          refresh_token: refresh_token,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        removeAccessTokenFromLocalStorage();
        console.log("LOgout Response");
      })
      .catch((err) => {
        console.log(err);
        setshow(false);
        alert(err);
      });
  };

  return (
    <>
      <Loader show={show} setshow={setshow} />
      <nav className="nav">
        <div className="logo">
          <img
            src="https://www.etechcube.com/wp-content/uploads/2021/03/cropped-cropped-cropped-cropped-color_logo_transparent-1-1-1.png"
            width={100}
            height={30}
          />
        </div>
        <ul className={`menuList  ${isMenuOpen ? "show" : "not"} `}>
          <li style={{ color: "black" }}>
            <Link
              onClick={() => {
                setActiveItem("home");
              }}
              to="/"
            >
              Home
            </Link>
          </li>
          {/* <li>
                <Link
                  
                  onClick={() =>{ setActiveItem("Dashboard")}}
                  to="/dashboard"
                >
                  Dashboard
                </Link>
              </li> */}

          {/* <li>
                <Link
                 
                  onClick={() => {setActiveItem("Profile")}}
                  to="/profile"
                >
                  Profile
                </Link>
              </li> */}

          {/* <li>
                <Link
                
                  onClick={() => {setActiveItem("Qr_code")}}
                  to="/about"
                >
                 About
                </Link>
              </li> */}

          <li>
            <button
              type="button"
              className={"Second"}
              style={{
                borderRadius: "5px",
                background: "blue",
                color: "white",
              }}
              onClick={() => LogOutData()}
            >
              LogOut
              <FiLogOut />
            </button>
          </li>
          <div className="profile-dropdown">
             
            <img  onClick={toggleProfileDropdown}
              className="profile-dropdown-toggle"
                src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg"/> 
              
            {showProfileDropdown && (
              <ul className="profile-dropdown-menu">
                <li className="profile-dropdown-item">
                  <a href="#">Profile</a>
                </li>
                <li className="profile-dropdown-item">
                  <a href="#">Settings</a>
                </li>
                <li className="profile-dropdown-item">
                  <button onClick={handleLogout}>Logout</button>
                </li>
              </ul>
            )}
          </div>
        </ul>

        <div
          className="menuIcon"
          onClick={() => {
            toggleMenu();
          }}
        >
          ☰
        </div>
      </nav>
    </>
  );
};

export default Header;
