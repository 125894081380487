// import React, { lazy } from "react";
// import { Navigate } from "react-router-dom";
// import Not_Found from "./utilites/Not_Found";
// import Login from "./pages/authentication/Login";
// import Home from "./pages/home/Home";
// import Signup from "./pages/authentication/Signup";
// import ForgotPassword from "./pages/authentication/ForgotPassword";

// import DownloadTextFile from "./function/DownloadTextFile";
// import DownloadVCF from "./function/DownloadVCF";
// import DownloadPDF from "./function/DownloadPDF"; 
// import Nfc from "./pages/nfc/Nfc";

// const auth_routes = [
//     { path: "/login", element: <Login /> },
//     { path: "/", exact: true, element: <Navigate to="/login" /> },
//     { path: "/404", element: <Navigate to="/login" replace /> },
//     { path: "/signup",  element: <Signup/> },
//     { path: "/forgot",  element: <ForgotPassword/> },
//     { path: "/DownloadTextFile", element: <DownloadTextFile/> },
//     { path: "/DownloadVCF", element: <DownloadVCF/> },
//     { path: "/DownloadPDF", element: <DownloadPDF/> },
// ]

// const routes = [ 
//     { path:"/" , element: <Home/>},
//     { path: "/404", element: <Not_Found/> },
//     { path: "*", element: <Navigate to="/404" replace /> },
  
//     { path: "/DownloadTextFile", element: <DownloadTextFile/> },
//     { path: "/DownloadVCF", element: <DownloadVCF/> },
//     { path: "/DownloadPDF", element: <DownloadPDF/> },
//     {path:"/nfc",element:<Nfc/>}


// ]

// export {auth_routes , routes}

import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

import NotFound from "./utilites/Not_Found";
const Login = lazy(() => import("./pages/authentication/Login"));
const Home = lazy(() => import("./pages/home/Home"));
const Signup = lazy(() => import("./pages/authentication/Signup"));
const ForgotPassword = lazy(() => import("./pages/authentication/ForgotPassword"));
const DownloadTextFile = lazy(() => import("./function/DownloadTextFile"));
const DownloadVCF = lazy(() => import("./function/DownloadVCF"));
const DownloadPDF = lazy(() => import("./function/DownloadPDF"));
const Nfc = lazy(() => import("./pages/nfc/Nfc"));

const authRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/forgot", element: <ForgotPassword /> },
  { path: "/download-text-file", element: <DownloadTextFile /> },
  { path: "/download-vcf", element: <DownloadVCF /> },
  { path: "/download-pdf", element: <DownloadPDF /> },
  { path: "*", element: <Navigate to="/login" replace /> }
];

const appRoutes = [
  { path: "/", element: <Home /> },
  { path: "/download-text-file", element: <DownloadTextFile /> },
  { path: "/download-vcf", element: <DownloadVCF /> },
  { path: "/download-pdf", element: <DownloadPDF /> },
  { path: "/nfc", element: <Nfc /> },
  { path: "*", element: <NotFound /> }
];

export { authRoutes, appRoutes };
