import React from 'react';
import Err_img from "../assets/Err_img.png";
import "./Not_Found.css";
import { Link, useNavigate } from "react-router-dom";
const NotFound = () => {

  const navigate = useNavigate();
  return (
    <div className='h_div'>
        <img src={Err_img} alt="Cat" className='err_img' />
      <div>
        <h1><b>404 - Not Found</b></h1>
        <p>The Page You're Looking For Does Not Exist.<b onClick={() => navigate(-1)}>Go Back !</b></p>
      </div>
    </div>
  );
};

export default NotFound;
