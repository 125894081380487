import { combineReducers, configureStore } from '@reduxjs/toolkit'
import AuthSlice from './store/AuthSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const persistConfig = {
    key:"root",
    version : 1,
    storage,
}
const reducer = combineReducers({
    authinfo: AuthSlice,
});

const persistedReducer = persistReducer(persistConfig , reducer)
export const store = configureStore({
  reducer: persistedReducer,
  // to solve error of not seralized value
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})