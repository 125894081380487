import React from "react";
import companyLogo from "../assets/etechcube.png"; // Import your company logo image
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="content-container">
        
        <div className="company">
        <img src={companyLogo} alt="Company Logo" style={{ width: "150px" }} />
          <h4>Etechcube LLP</h4>
          <p className="hover-underline">Address: C/O: J P Singh, Plot No: 5, Road No: 1, Vivek Nagar P/O: G H Colony, Chhota, Govindpur, Jamshedpur, Jharkhand 831015</p>
          <p className="hover-underline">Email: karan@etechcube.com</p>
          <p className="hover-underline">Phone: +1234567890</p>
          <p className="hover-underline">
            Website: <a href="https://www.etechcube.com/" target="_blank" rel="noopener noreferrer">https://www.etechcube.com/</a>
          </p>
        </div> 

        <div className="resources">
          <h4>Resources</h4>
          <ul className="list-style">
            <li>
              <a href="#" className="hover-underline">About</a>
            </li>
            <li>
              <a href="#" className="hover-underline">Jobs</a>
            </li>
            <li>
              <a href="#" className="hover-underline">Contacts</a>
            </li>
            <li>
              <a href="#" className="hover-underline">Collaborate</a>
            </li>
          </ul>
        </div>
        
        <div className="footer-links">
          <h4>Follow Us</h4>
          <ul className="list-style">
            <li>
              <a href="#" className="hover-underline">
                Facebook
              </a>
            </li>
            <li>
              <a href="#" className="hover-underline">
                Twitter
              </a>
            </li>
            <li>
              <a href="#" className="hover-underline">
                Instagram
              </a>
            </li>
            <li>
              <a href="#" className="hover-underline">
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        <p>Designed and Developed by: Etechcube LLP</p>
      </div>
    </footer>
  );
};

// Styles

export default Footer;
